import request from '@/utils/request'

// 查询短信渠道（阿里，腾讯，钉钉等）列表
export function listChannel(query) {
  return request({
    url: '/extra/sms-channel/list',
    method: 'get',
    params: query
  })
}

// 查询短信渠道（阿里，腾讯，钉钉等）详细
export function getChannel(id) {
  return request({
    url: '/extra/sms-channel/get?id=' + id,
    method: 'get'
  })
}

// 新增短信渠道（阿里，腾讯，钉钉等）
export function addChannel(data) {
  return request({
    url: '/extra/sms-channel/create',
    method: 'post',
    data: data
  })
}

// 修改短信渠道（阿里，腾讯，钉钉等）
export function updateChannel(data) {
  return request({
    url: '/extra/sms-channel/update',
    method: 'put',
    data: data
  })
}

// 删除短信渠道（阿里，腾讯，钉钉等）
export function delChannel(id) {
  return request({
    url: '/extra/sms-channel/delete?id=' + id,
    method: 'delete'
  })
}

// 获得短信渠道精简列表
export function getSimpleSmsChannels(){
  return request({
    url: '/extra/sms-channel/list-all-simple',
    method: 'get',
  })
}
